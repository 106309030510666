
export enum HandlingUnitMovementTypeCd {
	CLOSE = 'Close',
	DELIVER = 'Deliver',
	DOCK_OPERATIONS_EXCEPTION = 'DockOperationsException',
	LOAD = 'Load',
	PICKUP = 'Pickup',
	SCAN = 'Scan',
	SPLIT = 'Split',
	STAGE = 'Stage',
	TRANSFER = 'Transfer',
	UNLOAD = 'Unload'}

