
export enum ReweighStatusCd {
	APPROVED = 'Approved',
	ERROR = 'Error',
	NEW = 'New',
	PENDING = 'Pending',
	REPLACED = 'Replaced',
	RESUBMIT = 'Resubmit',
	WITHIN_TOLERANCE = 'WithinTolerance'}

